<template>
  <v-app>
    <v-app-bar
      app
      dark
      fixed
      height="50px"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="pl-2">
        {{ pageTitle }}
      </v-toolbar-title>

      <v-spacer />
      <span
        class="accent--text"
        @click="onRefresh"
      >{{ userInfo.userName }}</span>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      style="z-index: 999999 !important;background-color:rgb(27, 27, 27)"
      fixed
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            HiSFS(MES)
          </v-list-item-title>
          <v-list-item-subtitle>
            POP
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          color="#E65100"
        >
          <div
            v-for="item in menuItems"
            :key="item.no"
          >
            <v-list-item
              v-if="item.title !== &quot;divided&quot;"
              class="v-list-item-active"
              link
              @click.stop="onMenuClcik(item.path)"
            >
              <v-list-item-icon class="mr-8">
                <v-icon :color="item.color">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="item.title === &quot;divided&quot;" />
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </v-app>
</template>

<script>
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../share/AppLib'
import axios from 'axios'
import Loading from 'vue-loading-overlay'

export default {
  name: 'MainPage',
  components: {
    Loading
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      pageTitle: 'Main Page',
      drawer: false,
      group: null,
      menuItems: null,
      dencity: null
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/').catch(() => {})
      return
    }
    this.$store.commit('saveViewWidth', this.$Q('html').width())
    if (navigator.userAgent.indexOf('M3SM') >= 0 || navigator.userAgent.indexOf('PM85') >= 0 || navigator.userAgent.indexOf('PM90') >= 0) {
      this.$store.commit('saveMobileModel', 'PDA')
    } else if (AppLib.isTabletDevice() === true) {
      this.$store.commit('saveMobileModel', 'TABLET')
    } else {
      this.$store.commit('saveMobileModel', 'MOBILE')
    }
    const where = this.$_.findWhere(this.menuItems, { path: this.$route.path })
    if (where === undefined) {
      this.pageTitle = 'Hi-SFS(MES)'
    } else {
      this.pageTitle = this.$route.name
    }
    this.initBaseData()
  },
  mounted () {
    this.dencity = window.devicePixelRatio
    if (this.dencity > 2) {
      this.$Q('html').css('font-size', '18px')
    }
  },
  updated () {
    this.pageTitle = this.$route.name
  },
  methods: {
    async initBaseData () {
      this.isLoading = true
      // const goods = this.$_sp.runNoEncodeFindSqlProc('품목정보', null)
      const popMenu = this.$_sp.runFindSqlProc('POP메뉴', { visible: 1 })
      const sysParam = this.$_sp.runNoEncodeFindProc('spFindAllSysParamByNoSql', {})
      const baseCode = this.$_sp.runNoEncodeFindSqlProc('공통코드', null)
      const company = this.$_sp.runNoEncodeFindSqlProc('거래처정보', null)
      let lastUpdateTime = null
      await await this.$_sp.runNoEncodeFindProc('spFindOneLastUpdateTimeGoodsInfo', null)
        .then(data => {
          lastUpdateTime = this.$_sp.MakeModel(data)[0].updatetime
          if (lastUpdateTime === null) {
            lastUpdateTime = 0
          }
          // console.log(AppLib.formatDateTimeFromTimeStamp(lastUpdateTime))
        })
      let lastSaveTime = this.$store.getters.getProductInfoSaveTime
      if (lastSaveTime !== 0) {
        if (lastSaveTime < lastUpdateTime) {
          lastSaveTime = 0
        }
      }
      if (lastSaveTime === 0) {
        console.log('getProductInfo')
        await this.$_sp.runNoEncodeFindSqlProc('품목정보', null)
          .then((data) => {
            this.$store.commit('saveProductList', this.$_sp.MakeModel(data))
            this.$store.commit('saveProductInfoSaveTime', lastUpdateTime)
          })
          .catch(error => {
            this.isLoading = false
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      }
      await axios.all([sysParam, baseCode])
        .then(axios.spread((...reponse) => {
          if (reponse[0].result === ConstDef.SUCCESS) {
            const sysParam = this.$_sp.MakeModel(reponse[0])
            this.$store.commit('saveSysParam', sysParam)
            const ENCODE_BASE64 = this.$_.findWhere(sysParam, { key: 'QR_BASE64_ENCODE' }).value
            if (ENCODE_BASE64 === 'true') {
              ConstDef.ENCODE_BASE64 = true
            } else {
              ConstDef.ENCODE_BASE64 = false
            }
            console.log(reponse[0])
            this.$store.commit('saveBaseData', this.$_sp.MakeModel(reponse[1]))
          }
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      await axios.all([popMenu, company])
        .then(axios.spread((...reponse) => {
          if (reponse[0].result === ConstDef.SUCCESS) {
            this.menuItems = this.$_sp.MakeModel(reponse[0])
            this.$store.commit('saveCompany', this.$_sp.MakeModel(reponse[1]))
          }
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      this.isLoading = false
    },
    onMenuClcik (path) {
      this.drawer = !this.drawer
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    onRefresh () {
      this.$store.commit('deleteProductInfoSaveTime')
      location.reload(true)
    }
  }
}
</script>
<style lang="scss">
  .v-divider{
    border-color: rgb(155, 0, 0)!important;
    color: rgb(27, 27, 27);
  }
</style>
